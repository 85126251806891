/*!
 *  - fukushi.js
 * @generated Thu Aug 08 2024 09:54:35 GMT+0900 (GMT+09:00)
 */
'use strict';

window.console || (window.console = {
	log: function log() {}
});
var FUNCTIONS = window.FUNCTIONS || null;
// var cssua = window.cssua || null;

(function ($) {
	var siteName = 'dcm';
	// const clickEvent = cssua.ua.desktop ? 'click' : 'tap';
	var a = 'is-active';
	var v = 'is-visible';
	var f = 'is-fixed';
	var slideSpeed = '400';

	FUNCTIONS = {
		va: {
			pathname: location.pathname,
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: $(window).width(),
				height: $(window).height()
			}
		},
		localDecision: function localDecision() {
			var regExp = new RegExp('(test\\.' + siteName + '\\.connecty\\.co\\.jp|copre\\.jp|localhost|192\\.168\\.0\\.|192\\.168\\.11\\.|192\\.168\\.1\\.)', 'g');
			return regExp.test(location.hostname);
		},
		localLoading: function localLoading() {
			var _this = this;
			// ローカル環境ならincludeをAjaxで読み込む
			var key = 'inc_';
			var ajaxArray = [];
			var ajaxArrayList = [];
			var i, j;
			var includeClass = document.querySelectorAll('[class*="' + key + '"]');
			var includeClassLen = includeClass.length;

			for (i = 0; i < includeClassLen; i++) {
				var path = includeClass[i].innerHTML.split(' ')[1];
				ajaxArray.push(path);
			}
			var ajaxLen = ajaxArray.length;

			if (ajaxLen > 0) {
				for (i = 0; i < ajaxLen; i++) {
					ajaxArrayList[i] = $.ajax({
						type: 'GET',
						url: ajaxArray[i] + '.html',
						dataType: 'html',
						timeout: 5000
					});
				}
				$.when.apply($, ajaxArrayList).done(function () {
					for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
						args[_key] = arguments[_key];
					}

					var regExp = new RegExp(key);

					for (i = 0; i < args.length; i++) {
						var result = args[i];
						var position = $(result[0]).filter(':first').attr('class').split(' ');

						for (j = 0; j < position.length; j++) {
							if (position[j].match(regExp)) {
								position = position[j];
								break;
							}
						}
						$('.' + position).html(result[0]).children().unwrap();

						console.log('Succeeded to read the include file!:', position);
					}
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		catchAjaxError: function catchAjaxError(_errorThrown) {
			if (window.ga) {
				window.ga('send', 'event', 'ajaxError', location.href, _errorThrown, 0, true);
			}
		},
		getQuery: function getQuery() {
			var vars = [];
			var hash = null;
			var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			for (var i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			}
			return vars;
		},

		// ＜ルート設定対応＞要素が空だったら削除
		removeElmIfEmpty: function removeElmIfEmpty() {
			if ($('.js-checkEmptyElm').length) {
				for (var i = 0; i < $('.js-checkEmptyElm').length; i++) {
					if (!$('.js-checkEmptyElm').eq(i).text().replace(/^\s+/, '').length) {
						$('.js-checkEmptyElm').eq(i).parents('.js-checkEmptyList-wrap').remove();
					}
				}
			}
			if ($('.js-checkEmptyElmDi_none').length) {
				for (var _i = 0; _i < $('.js-checkEmptyElmDi_none').length; _i++) {
					if (!$('.js-checkEmptyElmDi_none').eq(_i).text().replace(/^\s+/, '').length) {
						$('.js-checkEmptyElmDi_none').eq(_i).parents('.js-checkEmptyElmDi_none-wrap').addClass('di_none');
					}
				}
			}
		},
		nextToggle: function nextToggle() {
			var $switch = $('.js-toggle');

			$switch.on('click', function () {
				$(this).toggleClass(a);
				$(this).next('.js-toggleBlock').stop().slideToggle(slideSpeed);
			});
		},
		easeScroll: function easeScroll() {
			$('a[rel="scroll"]').on('click', function (event) {
				var speed = 1200;
				if (/#/.test(event.currentTarget.href)) {
					scrollToObject(event.currentTarget.href, speed);
				}
				return false;
			});
			function scrollToObject(_self, _speed) {
				var hash = '#' + _self.split('#')[1];
				var pos = hash === '#pagetop' ? 0 : $(hash).offset().top;

				if (hash !== '#pagetop') {
					if (FUNCTIONS.va.window.width < 1025) {
						pos -= 60;
					} else {
						pos -= 68;
					}
				}

				$('html,body').stop().animate({ scrollTop: pos }, _speed, 'easeOutExpo');
			}
		},
		pageTop: function pageTop() {
			if ($('.footer-pagetop').length) {
				var $pagetop = $('.footer-pagetop');

				$(window).scroll(function (e) {
					var $this = $(e.currentTarget);
					var $footer = $('.footer');
					var footerPos = $footer.offset().top + $footer.height();
					var thisScroll = $this.scrollTop();
					var pagetopPos = $this.height() + $footer.height() + thisScroll;

					if (thisScroll > '150') {
						$pagetop.addClass(v);
					} else {
						$pagetop.removeClass(v);
					}

					if (pagetopPos > footerPos + 60) {
						$pagetop.addClass(f);
					} else {
						$pagetop.removeClass(f);
					}
				});
			}
		},
		headerMenu: function headerMenu() {
			var $menu = $('.js-header-menu');
			var $menucontent = $('.js-header-menu-content');
			var $menuback = $('.js-header-menu-back');
			var $headerSearchBtn = $('.js-header-search-btn');
			var $headerSearchBlock = $('.js-header-search-block');
			var flag = false;
			var scrollTopNow;

			// サイト内検索部分
			$headerSearchBtn.on('click', function () {
				$(this).toggleClass(a);
				$headerSearchBlock.toggleClass(a);
			});

			// ハンバーガーメニュー
			$menu.on('click', function () {
				if (!flag) {
					scrollTopNow = document.body.scrollTop + document.documentElement.scrollTop;
					$menu.addClass(a);
					$menucontent.addClass(a);
					$('.container').css({
						position: 'fixed',
						width: '100%',
						top: -1 * scrollTopNow,
						left: 0
					});
					$('body').addClass(f);
					flag = true;
				} else {
					$menu.removeClass(a);
					$menucontent.removeClass(a);
					$('.container').css({
						position: 'static'
					});
					$('body').removeClass(f);

					$(window).scrollTop(scrollTopNow);
					$('.header-nav-content-li-in').removeClass(v);
					$('.header-nav-content-li_menu').stop().slideUp(400);
					flag = false;
				}
			});
			$menuback.on('click', function () {
				$menu.removeClass(a);
				$menucontent.removeClass(a);
				$('.container').css({
					position: 'static'
				});
				$('body').removeClass(f);

				$(window).scrollTop(scrollTopNow);
				$('.header-nav-content-li-in').removeClass(v);
				$('.header-nav-content-li_menu').stop().slideUp(400);
				flag = false;
			});
		},
		headerNav: function headerNav() {
			var $headernav = $('.header-nav');
			var $headersearch = $('.header-nav-content-li-search-block');

			$(window).scroll(function () {
				var navPos = 151 - $(window).scrollTop();
				if ($(window).scrollTop() >= $('.header').height()) {
					$headernav.addClass(f);
					$headersearch.addClass(f);
					if (!(FUNCTIONS.va.window.width < 1024)) {
						$('.header-nav-content-li_menu').css('top', 68);
					}
				} else {
					$headernav.removeClass(f);
					$headersearch.removeClass(f);
					if (!(FUNCTIONS.va.window.width < 1024)) {
						$('.header-nav-content-li_menu').css('top', navPos);
					}
				}
			});
		},
		headerMegaMene: function headerMegaMene() {
			var $btn = $('.header-nav-content-li-in');
			var $item = $('.header-nav-content-li_menu');
			var $content = $('main');

			var urlPath = location.pathname.split('/')[1];

			if (location.pathname.split('/')[1] === 'kahma' || location.pathname.split('/')[1] === 'daiki' || location.pathname.split('/')[1] === 'homac' || location.pathname.split('/')[1] === 'sanwa' || location.pathname.split('/')[1] === 'kuroganeya' || location.pathname.split('/')[1] === 'hodaka' || location.pathname.split('/')[1] === 'grp') {
				if (!(location.pathname.split('/')[2] === 'english')) {
					urlPath = location.pathname.split('/')[2];
				} else {
					urlPath = location.pathname.split('/')[3];
				}
			}

			$('#current-' + urlPath).addClass('current');

			if (!(FUNCTIONS.va.window.width < 1024)) {
				$btn.on('mouseenter', function () {
					$btn.removeClass(v);
					$item.stop().slideUp(400);
					$(this).addClass(v);
					$(this).next($item).stop().slideDown(400);
				});
				$content.on('mouseenter', function () {
					$btn.removeClass(v);
					$item.stop().slideUp(400);
				});
			} else {
				$btn.on('click', function () {
					$(this).toggleClass(v);
					$(this).next($item).stop().slideToggle(400);
				});
			}
		},
		share: function share() {
			if ($('.js-share-btn').length) {
				var url = document.URL;
				$('.js-share-btn-facebook').attr('data-href', url);
				$('.js-share-btn-line').each(function (idx, ele) {
					var url = document.URL;
					var title = encodeURIComponent(document.title);
					var $this = $(ele);
					var href = $this.attr('href').replace(/#link/ig, url).replace(/#text/ig, title);
					$this.attr('href', href);
				});
			}
		},
		search: function search() {
			var $freewordSerchBtn = $('.js-freeword-search-btn');
			var $freewordSerchInput = $('.js-freeword-search-input');

			$($freewordSerchInput).change(function () {
				$freewordSerchBtn.eq(0).attr('href', '/search/?p_keyword=' + encodeURI($(this).val()));
				$freewordSerchBtn.eq(1).attr('href', '/search/?p_keyword=' + encodeURI($(this).val()));
			});

			// 検索部分でエンターを押したときにボタンクリックと同じ処理を追加
			if (!($(window).width() < 1025)) {
				$($freewordSerchInput).keypress(function (e) {
					if (e.which === 13) {
						window.location.href = '/search/?p_keyword=' + encodeURI($(this).val());
						return false;
					}
				});
			}
		},
		marsflag: function marsflag() {
			var el = document.createElement('script');
			if (!(FUNCTIONS.va.window.width < 1024)) {
				$('.js-marsflag-add-pc').append('<div id="srchBox" class="watermark">' + '<div class="mf_finder_header" id="srch">' + '<mf-search-box submit-text="検索" ajax-url="https://finder.api.mf.marsflag.com/api/v1/finder_service/documents/97cadf82/search" serp-url="/search/index.html#/" imgsize-default="2" doctype-hidden options-hidden></mf-search-box>' + '</div></div>');
			} else {
				$('.js-marsflag-add-sp').append('<div id="srchBox" class="watermark">' + '<div class="mf_finder_header" id="srch">' + '<mf-search-box submit-text="検索" ajax-url="https://finder.api.mf.marsflag.com/api/v1/finder_service/documents/97cadf82/search" serp-url="/search/index.html#/" imgsize-default="2" doctype-hidden options-hidden></mf-search-box>' + '</div></div>');
			}
			if (!(location.pathname.split('/')[1] === 'search')) {
				// マーズフラッグ検索窓用js
				el.src = 'https://ce.mf.marsflag.com/latest/js/mf-search.js';
			} else {
				// マーズフラッグ検索結果用js
				el.src = 'https://ce.mf.marsflag.com/latest/js/mf-search.js';
			}
			setTimeout(function () {
				document.body.appendChild(el);
			}, 100);
		},
		fontSizeJs: function fontSizeJs() {
			if ($('.js-fssize').length) {
				var $fsSizeBtn = $('.js-fssize');
				var $fsSizeMinBtn = $('.js-fssize-min');
				var $fsSizeMiddleBtn = $('.js-fssize-middle');
				var $fsSizeBigBtn = $('.js-fssize-big');
				$fsSizeMinBtn.on('click', function () {
					$fsSizeBtn.removeClass(a);
					$('html').removeClass('__fs-middle');
					$('html').removeClass('__fs-big');
					$fsSizeMinBtn.addClass(a);
					$('html').addClass('__fs-min');
				});
				$fsSizeMiddleBtn.on('click', function () {
					$fsSizeBtn.removeClass(a);
					$('html').removeClass('__fs-min');
					$('html').removeClass('__fs-big');
					$fsSizeMiddleBtn.addClass(a);
					$('html').addClass('__fs-middle');
				});
				$fsSizeBigBtn.on('click', function () {
					$fsSizeBtn.removeClass(a);
					$('html').removeClass('__fs-min');
					$('html').removeClass('__fs-middle');
					$fsSizeBigBtn.addClass(a);
					$('html').addClass('__fs-big');
				});
			}
		},
		eventLinkJs: function eventLinkJs() {
			// eventのリンク生成用
			if ($('.js-eventLink').length) {
				var jsLink = void 0,
				    jsDirectory = void 0,
				    jsCategory = void 0,
				    jsDetail = void 0;
				for (var i = 0; i < $('.js-eventLink').length; i++) {
					jsDirectory = $('.js-eventLink').eq(i).find('.rt_cf_s_shop_brand').eq(0).text();
					if (jsDirectory === 'dcm_brand') {
						jsDirectory = 'dcm';
					} else if (jsDirectory === 'dcm_kahma_pet') {
						jsDirectory = 'kahma';
					} else if (jsDirectory === 'dcm_kahma_pro') {
						jsDirectory = 'kahma';
					} else if (jsDirectory === 'dcm_kahma_athome') {
						jsDirectory = 'kahma';
					} else if (jsDirectory === 'dcm_kahma_21') {
						jsDirectory = 'kahma';
					} else if (jsDirectory === 'dcm_kahma') {
						jsDirectory = 'kahma';
					} else if (jsDirectory === 'dcm_daiki') {
						jsDirectory = 'daiki';
					} else if (jsDirectory === 'dcm_homac_ouchi') {
						jsDirectory = 'homac';
					} else if (jsDirectory === 'dcm_homac_heartful') {
						jsDirectory = 'homac';
					} else if (jsDirectory === 'dcm_homac') {
						jsDirectory = 'homac';
					} else if (jsDirectory === 'dcm_sanwa') {
						jsDirectory = 'sanwa';
					} else if (jsDirectory === 'dcm_sanwa') {
						jsDirectory = 'sanwa';
					} else if (jsDirectory === 'dcm_kuroganeya') {
						jsDirectory = 'kuroganeya';
					} else if (jsDirectory === 'homac_nicot') {
						jsDirectory = 'homac';
					}
					// console.log('jsDirectory',jsDirectory);
					jsCategory = $('.js-eventLink .rt_cf_ec_code').eq(i).text();
					jsDetail = $('.js-eventLink .rt_cf_e_id').eq(i).text();
					jsLink = '/' + jsDirectory + '/event/' + jsCategory + '/' + jsDetail + '.html';
					$('.js-eventLink').eq(i).attr('href', jsLink);
				}
			}
		},
		recommendLinkJs: function recommendLinkJs() {
			// recommendのリンク生成用
			if ($('.js-recommendLink').length) {
				var jsLink = void 0,
				    jsDirectory = void 0,
				    jsCategory = void 0,
				    jsDetail = void 0;
				for (var i = 0; i < $('.js-recommendLink').length; i++) {
					jsDirectory = $('.js-recommendLink').eq(i).find('.js-recommendLink-directory01').eq(0).text();
					if (jsDirectory === 'column') {
						jsDirectory = 'kurashimade';
					} else if (jsDirectory === 'series') {
						jsDirectory = 'kurashimade/series';
					} else if (jsDirectory === 'guide') {
						jsDirectory = 'howto/guide';
					}
					console.log('jsDirectory', jsDirectory);
					jsCategory = $('.js-recommendLink').eq(i).find('.js-recommendLink-directory02').eq(0).text();
					jsDetail = $('.js-recommendLink').eq(i).find('.js-recommendLink-directory03').eq(0).text();
					jsLink = '/' + jsDirectory + '/' + jsCategory + '/' + jsDetail + '.html';
					$('.js-recommendLink').eq(i).attr('href', jsLink);
				}
			}
		},

		// キーワード検索(ブランドサイト)
		brandSearchKeyword: function brandSearchKeyword() {
			var kewordSearch = function kewordSearch(_input, _btn) {
				_btn.on('click', function () {
					if (!_input.val()) {
						alert('キーワードを入力してください。');
					} else {
						window.location.href = '/shop/search/list.html?keyword=' + encodeURI(_input.val());
					}
				});
				_input.keypress(function (e) {
					if (e.which === 13) {
						if (!_input.val()) {
							alert('キーワードを入力してください。');
						} else {
							window.location.href = '/shop/search/list.html?keyword=' + encodeURI(_input.val());
						}
						return false;
					}
				});
			};
			kewordSearch($('.js-header-keyword-input'), $('.js-header-keyword-btn'));
			kewordSearch($('.js-index-keyword-input'), $('.js-index-keyword-btn'));
		},

		// 県名検索(ブランドサイト)
		brandSearchPrefecture: function brandSearchPrefecture() {
			var brand = location.pathname.split('/')[1];
			if (brand === 'dcm') {
				brand = 'dcm_brand';
			}
			if (brand == 'kahma' || brand == 'daiki' || brand == 'homac' || brand == 'sanwa' || brand == 'kuroganeya' || brand == 'hodaka' || brand == 'dcm_brand') {
				var getJSONbrandPrefecture = function getJSONbrandPrefecture() {
					return $.getJSON('/ajax/shop/' + brand + '/prefecture.json');
				};
				var prefectureSearch = function prefectureSearch(_select, _btn) {
					_select.css('pointer-events', 'none');
					getJSONbrandPrefecture().done(function (data) {
						// console.log(data,'data');
						var list = data.rt_bn_prefecture_list;
						for (var i = 0; i < list.length; i++) {
							_select.append($('<option>').html(list[i].cf_s_prefecture).val(list[i].cf_s_prefecture_code));
						}
						_select.css('pointer-events', 'auto');
					});
					_btn.on('click', function () {
						if (!_select.val()) {
							alert('都道府県を選択してください');
						} else {
							if (brand == 'kahma') {
								window.location.href = '/shop/search/' + _select.val() + '/?p_city=&p_brand=dcm_kahma&p_brand=dcm_kahma_21&p_brand=dcm_kahma_athome&p_brand=dcm_kahma_pro&p_brand=dcm_kahma_pet';
							} else if (brand == 'daiki') {
								window.location.href = '/shop/search/' + _select.val() + '/?p_city=&p_brand=dcm_daiki';
							} else if (brand == 'homac') {
								window.location.href = '/shop/search/' + _select.val() + '/?p_city=&p_brand=dcm_homac&p_brand=dcm_homac_ouchi&p_brand=dcm_homac_heartful';
							} else if (brand == 'sanwa') {
								window.location.href = '/shop/search/' + _select.val() + '/?p_city=&p_brand=dcm_sanwa';
							} else if (brand == 'kuroganeya') {
								window.location.href = '/shop/search/' + _select.val() + '/?p_city=&p_brand=dcm_kuroganeya';
							} else if (brand == 'hodaka') {
								window.location.href = '/shop/search/' + _select.val() + '/?p_city=&p_brand=hodaka';
							} else if (brand == 'dcm_brand') {
								window.location.href = '/shop/search/' + _select.val() + '/?p_city=&p_brand=dcm_brand';
							}
						}
					});
				};
				prefectureSearch($('.js-header-prefecture-select'), $('.js-header-prefecture-btn'));
				prefectureSearch($('.js-index-prefecture-select'), $('.js-index-prefecture-btn'));
			} else {
				var getJSONbrandPrefectureTop = function getJSONbrandPrefectureTop() {
					return $.getJSON('/ajax/shop/prefecture.json');
				};
				var prefectureSearchTop = function prefectureSearchTop(_select, _btn) {
					_select.css('pointer-events', 'none');
					getJSONbrandPrefectureTop().done(function (data) {
						// console.log(data,'data');
						var list = data.rt_bn_store_list;
						for (var i = 0; i < list.length; i++) {
							_select.append($('<option>').html(list[i].cf_s_prefecture).val(list[i].cf_s_prefecture_code));
						}
						_select.css('pointer-events', 'auto');
					});
					_btn.on('click', function () {
						if (!_select.val()) {
							alert('都道府県を選択してください');
						} else {
							window.location.href = '/shop/search/' + _select.val() + '/';
						}
					});
				};
				// prefectureSearchTop($('.js-header-prefecture-select'),$('.js-header-prefecture-btn'));
				prefectureSearchTop($('.js-index-prefecture-select'), $('.js-index-prefecture-btn'));
			}
		},
		modalhodaka: function modalhodaka() {
			$('.mod-modal-hodaka').on('click', function (e) {
				var scrollPos = $(window).scrollTop();
				$('html, body').css({ 'overflow': 'hidden', 'position': 'fixed', 'width': '100%' });
				var contents = '<div class="mod-modal-content">' + '<span class="mod-modal-closeBtn"><i class="icon icon-close"></i></span>' + $('.mod-modal-hodaka-html').html() + '</div>';
				contents += '<div class="mod-modal-closeArea"></div>';
				var HTML = '<div class="mod-modal-overlay">' + contents + '</div>';
				$('body').append(HTML);
				var $overlay = $('.mod-modal-overlay');
				$overlay.fadeIn(400, function () {
					$('.mod-modal-closeArea, .mod-modal-closeBtn').on('click', function () {
						$('body, html').removeAttr('style');
						$(window).scrollTop(scrollPos);

						$overlay.fadeOut(400, function () {
							$overlay.remove();
						});
					});
				});
			});
		},
		loadDelayScript: function loadDelayScript() {
			var _this = this;
			_this.removeElmIfEmpty();
			_this.nextToggle();
			_this.easeScroll();
			_this.pageTop();
			_this.headerMenu();
			_this.headerNav();
			_this.headerMegaMene();
			_this.share();
			// _this.search();
			_this.marsflag();
			_this.fontSizeJs();
			_this.eventLinkJs();
			_this.recommendLinkJs();
			_this.brandSearchKeyword();
			_this.brandSearchPrefecture();
			_this.modalhodaka();
		}
	};

	$(function () {
		return FUNCTIONS.localDecision() ? FUNCTIONS.localLoading() : FUNCTIONS.loadDelayScript();
	});
})(window.jQuery);
// easeScrollHash()
if (!(location.pathname.split('/')[1] === 'search')) {
	if (location.hash) {
		var hash = location.hash;
		$(window).on('load', function () {
			hash = '#' + location.hash.split('#')[1];
			var pos = hash === '#pagetop' ? 0 : $(hash).offset().top;

			if (hash !== '#pagetop') {
				if (FUNCTIONS.va.window.width < 1025) {
					pos -= 60;
				} else {
					pos -= 68;
				}
			}

			$('html,body').stop().animate({ scrollTop: pos }, 400, 'easeOutExpo');
		});
	}
}
document.addEventListener("DOMContentLoaded", function () {
	var lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));
	if ("IntersectionObserver" in window) {
		var lazyImageObserver = new IntersectionObserver(function (entries, observer) {
			entries.forEach(function (entry) {
				if (entry.isIntersecting) {
					var lazyImage = entry.target;
					lazyImage.src = lazyImage.dataset.src;
					if (typeof lazyImage.dataset.srcset === "undefined") {} else {
						lazyImage.srcset = lazyImage.dataset.srcset;
					}
					lazyImage.classList.remove("lazy");
					if (lazyImage.classList.contains("lazy-img")) {
						lazyImage.addEventListener("load", function () {
							var wrapElm = getClosest(lazyImage, ".lazy-imgWrap");
							wrapElm.classList.add("is-loaded");
						});
					}
					lazyImageObserver.unobserve(lazyImage);
				}
			});
		}, { rootMargin: "50px" });

		lazyImages.forEach(function (lazyImage) {
			lazyImageObserver.observe(lazyImage);
		});
	} else {
		// Possibly fall back to a more compatible method here
	}
	function getClosest(node, selector) {
		return (node.closest || function (_selector) {
			do {
				if ((node.matches || node.msMatchesSelector).call(node, _selector)) {
					return node;
				}
				node = node.parentElement || node.parentNode;
			} while (node !== null && node.nodeType === 1);

			return null;
		}).call(node, selector);
	}
});